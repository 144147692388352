<template>
    <div class="service" :style="serviceStyle">
        <div class="tap">
            <div class="main">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item v-if="type < 5" :to="{ path: '/enterprise' }">企业责任</el-breadcrumb-item>
                    <el-breadcrumb-item>{{obj[type].name}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
            <div class="content main">
                <div class="title">{{obj[type].title}}</div>
                <div v-html="obj[type].html" class="html">
                    
                </div>
            </div>
    </div>
</template>
<script>
import image1 from "../assets/PC/image1.png";
import image2 from "../assets/PC/image2.png";
import image3 from "../assets/PC/4cd9dc75-fe1c-4187-99e8-7ceb4646303d.png";
import image4 from "../assets/PC/16819696884024.png";
import image5 from "../assets/PC/16819697666969.png";
import image6 from "../assets/PC/16819699423063.png";
import image7 from "../assets/PC/16819699811182.png";
export default {
    data () {
        return {
            image1:image1,
            image2:image2,
            image3:image3,
            serviceStyle:"",
            type:1,
            obj:{
                '1':{
                    name:'知识产权',
                    title:'知识产权申请与维护',
                    html:`
                    <p>依蓝云公司十分注重对知识产权的保护，并积极实施知识产权保护政策。在全力维护依蓝云公司自有的知识产权的同时也充分尊重第三方权利人的知识产权。依蓝云公司开设专门的投诉渠道，积极处理用户在使用依蓝云公司产品和服务过程中存在的侵犯他人知识产权的事情。<p/>
                    `
                },
                '2':{
                    name:'用户隐私',
                    title:'保护用户隐私是我们的责任',
                    html:`
                    <p>我们一直致力为用户提供更佳的服务透明度，会不时更新依蓝云公司的隐私政策和服务条款。需注意，协议政策和服务条款适用于依蓝云针对的具体产品或服务。随着法律、技术、社会规范、业务需要及用户需求等方面的不断变化，我们的用户隐私及数据安全保护措施也将会持续发展及优化。保护依蓝云公司的用户隐私是我们一直重视的责任。<p/>
                    `
                },
                '3':{
                    name:'数据安全',
                    title:'可靠的数据安全保护',
                    html:`
                    <p>我们拥有专业的数据安全保护技术，这些技术目前应用于我们所有的产品及服务，支持整个产品数据管理生命周期，包括横跨数据建立/收集、传输、储存、使用、分享/转移及销毁各个阶段的安全技术措施及政策，旨为提供更可靠的数据安全防护。<p/>
                    `
                },
                '4':{
                    name:'全程守护',
                    title:'7*24小时全程守护',
                    html:`
                    <p>依蓝云公司为客户产品及公司旗下产品提供7*24小时全方位技术守护，并建立全方位的防护体系，旨在用科技为信息安全提供全方位保护，协助司法机关打击网络黑产及其他犯罪，治理电信网络诈骗，保障网民的合法权益。<p/>
                    `
                },
                '5':{
                    name:'售后服务',
                    title:'售后服务',
                    html:`
                    <p style="font-weight: 700;">城市95128热线解决方案咨询</p>
                    <p>QQ：10013721</p>
                    <p>WX：YLYUN-STAFF</p>
                    <p>咨询热线：0532-58661717-101</p>
                    <p>（早9:00-晚18:00）</p>
                    <div style="height: 16px;  width: 100%;"></div>
                    <p style="font-weight: 700;">城市定制客运解决方案咨询</p>
                    <p>QQ：10013721</p>
                    <p>WX：YLYUN-STAFF</p>
                    <p>咨询热线：0532-58661717-101</p>
                    <p>（早9:00-晚18:00）</p>
                    <div style="height: 16px;  width: 100%;"></div>
                    <p style="font-weight: 700;">政企信息化定制咨询</p>
                    <p>QQ：10013721</p>
                    <p>WX：YLYUN-STAFF</p>
                    <p>咨询热线：0532-58661717-101</p>
                    <p>（早9:00-晚18:00）</p>
                    <div style="height: 16px;  width: 100%;"></div>
                    <p style="font-weight: 700;">云计算产品咨询合作</p>
                    <p>QQ：10013721</p>
                    <p>WX：YLYUN-STAFF</p>
                    <p>咨询热线：0532-58661717-101</p>
                    <div style="height: 16px;  width: 100%;"></div>

                    <p>商务合作邮箱：b@uyl.cn</p>

                    <p>投诉建议邮箱：han@uyl.cn</p>

                    <p>产品反馈邮箱：shan@uyl.cn</p>
                    `
                },
                '6':{
                    name:'服务协议',
                    title:'依蓝云服务协议',
                    html:`
                    <p>欢迎您使用依蓝云的服务！</p>
                    <p>为使用依蓝云的服务，您应当阅读并遵守《依蓝云服务协议》（以下简称“本协议”）和《依蓝云隐私政策》。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制依蓝云责任的条款、对用户权利进行限制的条款、约定争议解决方式和司法管辖的条款（如第十八条相关约定）等，以及开通或使用某项服务的单独协议或规则。限制、免责条款或者其他涉及您重大权益的条款可能以加粗、加下划线等形式提示您重点注意。</p>
                    <p> 除非您已充分阅读、完全理解并接受本协议所有条款，否则您无权使用依蓝云服务。您点击“同意”或“下一步”，或您使用依蓝云服务，或者以其他任何明示或者默示方式表示接受本协议的，均视为您已阅读并同意签署本协议。本协议即在您与依蓝云之间产生法律效力，成为对双方均具有约束力的法律文件。</p>
                    <p> 如果您因年龄、智力等因素而不具有完全民事行为能力，请在法定监护人（以下简称"监护人"）的陪同下阅读和判断是否同意本协议，并特别注意未成年人使用条款。</p>
                    <p> 如果您是中国大陆地区以外的用户，您订立或履行本协议还需要同时遵守您所属和/或所处国家或地区的法律。 </p>
                    <p>一、【协议的范围】</p>
                    <p>1.1本协议是用户与依蓝云之间关于其使用依蓝云的服务所订立的协议。“依蓝云”是指依蓝云和/或其相关服务可能存在的运营关联单位。“用户”是指依蓝云的服务的使用人，在本协议中更多地称为“您”。</p>
                    <p>1.2依蓝云的服务是指依蓝云向用户提供的，包括但不限于即时通讯、网络媒体、互联网增值、互动娱乐、金融支付、广告等产品及服务，具体服务以依蓝云实际提供的为准（以下简称“本服务”）。</p>
                    <p>1.3本协议内容同时包括《依蓝云隐私政策》,且您在使用依蓝云某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”）。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。您对前述任何单独协议的接受，即视为您对本协议全部的接受。您对本协议的接受，即视为您对《依蓝云隐私政策》的接受。</p>

                    <p>二、【帐号与密码安全】</p>
                    <p>2.1您在使用本服务时可能需要注册一个帐号。关于您使用帐号的具体规则，请仔细阅读并遵守相关单独协议。</p>
                    <p>2.2依蓝云特别提醒您应妥善保管您的帐号和密码。当您使用完毕后，应安全退出。因您保管不善可能导致遭受被盗号或密码失窃，责任由您自行承担。</p>
                    <p>2.3为增强用户体验和/或技术便利，本服务的帐号可能包括数字、字母或者其组合，以及手机号码、电子信箱等多种形式。在您注册某一形式的帐号时，依蓝云可能附赠该帐号的另一表现形式。具体的帐号形式、帐号体系及帐号之间的关联关系等，以依蓝云实际提供的为准。</p>
                    <p>2.4您在使用本服务过程中，可能可以为您使用的帐号设置昵称、头像、签名、留言等信息，也可能为您建立或者管理、参与的QQ群、微信群等设置名称、图片、简介等信息。您应当保证这些信息的内容和形式符合法律法规（本协议中的“法律法规”指用户所属/所处地区、国家现行有效的法律、行政法规、司法解释、地方法规、地方规章、部门规章及其他规范性文件以及对于该等法律法规的不时修改和补充，以及相关政策规定等，下同。）、公序良俗、社会公德以及依蓝云平台规则，且不会侵害任何主体的合法权益。</p>
                    <p>2.5部分第三方网站或者服务可能可以用QQ帐号、微信帐号等作为其登录途径之一。您知晓，除非依蓝云特别说明外，这些网站或者服务并非依蓝云运营，您应自行判断此类第三方网站或者服务的安全性和可用性，并自行承担相关风险和责任。</p>

                    <p>三、【用户个人信息保护】</p>
                    <p>3.1保护用户个人信息是依蓝云的一项基本原则。依蓝云将按照本协议及《依蓝云隐私政策》的规定收集、使用、储存和分享您的个人信息。本协议对个人信息保护相关内容未作明确规定的，均应以《依蓝云隐私政策》的内容为准。</p>
                    <p>3.2您在注册帐号或使用本服务的过程中，可能需要填写一些必要的信息。若国家法律法规有特殊规定的，您需要填写真实的身份信息。若您填写的信息不完整，则可能无法使用本服务或在使用过程中受到限制。</p>
                    <p>3.3一般情况下，您可根据相关产品规则浏览、修改自己提交的信息，但出于安全性和身份识别（如号码申诉服务等）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。</p>
                    <p>3.4依蓝云将尽可能运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。</p>
                    <p>3.5依蓝云不会将您的个人信息转移或披露给任何第三方，除非：</p>
                    <p>（1）相关法律法规或司法机关、行政机关要求。</p>
                    <p>（2）为完成合并、分立、收购或资产转让而转移。</p>
                    <p>（3）为提供您要求的服务所必需。</p>
                    <p>（4）依据《依蓝云隐私政策》或其他相关协议规则可以转移或披露给任何第三方的情形。</p>
                    <p>3.6依蓝云非常重视对未成年人个人信息的保护。若您不具备完全民事行为能力，在使用依蓝云的服务前，应事先取得您的监护人的同意。</p>

                    <p>四、【使用本服务的方式】</p>
                    <p>4.1本服务仅为您个人非商业性质的使用，除非您与依蓝云另有约定。</p>
                    <p>4.2您依本协议条款所取得的权利不可转让。</p>
                    <p>4.3您不得使用任何方式（包括但不限于第三方软件、插件、外挂、系统、设备等）对本服务进行干扰、破坏、修改或施加其他影响。</p>
                    <p>4.4您应当通过依蓝云提供或认可的方式使用本服务，不得通过任何第三方软件、插件、外挂、系统、设备等登录或使用本服务。</p>
                    <p>4.5任何人未经依蓝云授权不得使用任何第三方软件、插件、外挂、系统等查看、获取本服务中所包含的依蓝云、依蓝云合作伙伴或用户的任何相关信息、数据等内容，同时，应当严格遵守依蓝云发布的Robots协议等相关协议规则。</p>

                    <p>五、【按现状提供服务】</p>
                    您理解并同意：
                    <p>5.1本服务是按照现有技术和条件所能达到的现状提供的。依蓝云会尽最大努力保障服务的连贯性和安全性，但依蓝云不能随时预见和防范法律、技术以及其他风险，依蓝云对此类风险在法律允许的范围内免责，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。</p>
                    <p>5.2因经营策略安排或调整等原因,不同地区的用户可使用的具体依蓝云服务的内容可能会存在差异，具体以依蓝云实际提供的为准。</p>
                    <p>六、【自备设备】</p>
                    <p>6.1您应当理解，您使用本服务需自行准备与相关服务有关的终端设备（如电脑、移动终端和必要的网络接入设备等装置），并承担所需的费用（如电话费、上网费等费用）。</p>
                    <p>6.2您理解并同意，您使用本服务时会耗用您的终端设备和带宽等资源。</p>

                    七、【广告】
                    <p>7.1您同意依蓝云可以自行或由第三方通过短信、电子邮件或电子信息等多种方式向您发送、展示广告或其他信息（包括商业与非商业信息），广告或其他信息的具体发送及展示形式、频次及内容等以依蓝云实际提供为准。</p>
                    <p>7.2依蓝云将依照相关法律法规要求开展广告业务。您同意，对本服务中出现的广告，您应审慎判断其真实性和可靠性，除法律明确规定外，您应对因该广告而实施的行为负责。</p>

                    <p>八、【服务费用】</p>
                    <p>8.1依蓝云的部分服务是以收费方式提供的，如您使用收费服务，请遵守相关的协议。</p>
                    <p>8.2依蓝云可能根据实际需要对收费服务的收费标准、方式进行修改和变更，依蓝云也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，依蓝云将在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，则应停止使用该服务。</p>
                    <p>8.3在依蓝云降低收费服务的收费标准或者将收费服务改为免费服务提供时，依蓝云保留不对原付费用户提供退费或者费用调整之权利。</p>

                    <p>九、【第三方提供的产品或服务】</p>
                    <p>您在依蓝云平台上使用第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议。依蓝云和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。</p>

                    <p>十、【基于软件提供服务】</p>
                    <p>若依蓝云依托“软件”向您提供服务，您还应遵守以下约定：</p>
                    <p>10.1您在使用本服务的过程中可能需要下载软件，对于这些软件，依蓝云给予您一项个人的、不可转让及非排他性的许可。您仅可为使用本服务的目的而使用这些软件。</p>
                    <p>10.2为了改善用户体验、保障服务的安全性及产品功能的一致性等目的，依蓝云可能会对软件进行更新。您应该将相关软件更新到最新版本，否则依蓝云并不保证软件或服务能正常使用。</p>
                    <p>10.3依蓝云可能为不同的终端设备开发不同的软件版本，您应当根据实际情况选择下载合适的版本进行安装。您可以直接从依蓝云平台上获取软件，也可以从得到依蓝云授权的第三方获取。如果您从未经依蓝云授权的第三方获取软件或与软件名称相同的安装程序，依蓝云无法保证该软件或服务能够正常使用，并对因此给您造成的损失不予负责。</p>
                    <p>10.4除非依蓝云书面许可，您不得从事下列任一行为：</p>
                    <p>（1）删除软件及其副本上关于著作权的信息。</p>
                    <p>（2）对软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现软件的源代码。</p>
                    <p>（3）对依蓝云拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等。</p>
                    <p>（4）对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经依蓝云授权的第三方工具/服务接入软件和相关系统。</p>
                    <p>（5）通过修改或伪造软件运行中的指令、数据等任何方式，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的。</p>
                    <p>（6）通过非依蓝云开发、授权的第三方软件、插件、外挂、系统、设备等任何方式，登录或使用依蓝云软件及服务，或制作、发布、传播非依蓝云开发、授权的用于登录或使用依蓝云软件及服务的第三方软件、插件、外挂、系统、设备等。</p>

                    <p>十一、【知识产权声明】</p>
                    <p>11.1依蓝云在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表、计算机软件等）的知识产权归依蓝云所有，用户在使用本服务中所产生内容的知识产权归用户或相关权利人所有，除非您与依蓝云另有约定。</p>
                    <p>11.2除另有特别声明外，依蓝云提供本服务时所依托软件的著作权、专利权及其他知识产权均归依蓝云所有。</p>
                    <p>11.3依蓝云在本服务中所使用的“依蓝云”、“YL”及海鸥形象等商业标识，其著作权或商标权归依蓝云所有。</p>
                    <p>11.4上述及其他任何本服务包含的内容的知识产权均受到法律法规保护，未经依蓝云、用户或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。</p>

                    <p>十二、【用户违法违规行为】</p>
                    <p>12.1您在使用本服务时须遵守法律法规，不得制作、复制、发布、传播含有下列内容的信息或从事相关行为，也不得为制作、复制、发布、传播含有下列内容的信息或从事相关行为提供便利：</p>
                    <p>（1）反对宪法所确定的基本原则的。</p>
                    <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的。</p>
                    <p>（3）损害国家荣誉和利益的。</p>
                    <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的。</p>
                    <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的。</p>
                    <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的。</p>
                    <p>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的。</p>
                    <p>（8）侮辱或者诽谤他人，侵害他人合法权益的。</p>
                    <p>（9）违反法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的。</p>
                    <p>（10）相关法律法规或本协议、相关协议、规则等禁止的。</p>
                    <p>12.2如果您在使用本服务过程中违反了相关法律法规或本协议约定，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求依蓝云给予协助。因此给您或者他人造成损害的，您应自行承担全部责任，依蓝云不承担任何责任。</p>
                    <p>12.3如果您违反本协议约定，依蓝云有权进行独立判断并采取相应措施，包括但不限于通过技术手段删除、屏蔽相关内容或断开链接等。同时，依蓝云有权视用户的行为性质，采取包括但不限于暂停或终止向您提供服务，限制、中止、冻结或终止您对QQ帐号、微信帐号的使用，追究法律责任等措施。</p>
                    <p>12.4您违反本协议约定，导致任何主体损失的，您应当独立承担责任；依蓝云因此遭受损失的，您也应当一并赔偿。</p>

                    <p>十三、【遵守当地法律监管】</p>
                    <p>13.1您在使用本服务过程中应当遵守当地相关的法律法规，并尊重当地的道德和风俗习惯。如果您的行为违反了当地法律法规或道德风俗，您应当为此独立承担责任。</p>
                    <p>13.2您应避免因使用本服务而使依蓝云违反法律法规或卷入政治和公共事件，否则依蓝云有权暂停或终止对您的服务。</p>

                    <p>十四、【用户发送、传播的内容与投诉处理】</p>
                    <p>14.1您通过本服务发送或传播的内容（包括但不限于网页、文字、图片、音频、视频、图表等）均由您自行承担责任。</p>
                    <p>14.2您发送或传播的内容应有合法来源，相关内容为您所有或您已获得必要的授权。</p>
                    <p>14.3如果您发送或传播的内容违法违规或侵犯他人权利的，依蓝云有权进行独立判断并采取删除、屏蔽或断开链接等措施。</p>
                    <p>14.4如您被他人投诉或您投诉他人，依蓝云有权将争议中相关方的主体信息、联系方式、投诉相关内容等必要信息提供给相关当事方或相关部门，以便及时解决投诉纠纷，保护各方合法权益。</p>
                    <p>14.5您保证对您在投诉处理程序中提供的信息、材料、证据等的真实性、合法性、有效性负责。</p>

                    <p>十五、【不可抗力及其他免责事由】</p>

                    <p>15.1您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务受到影响。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，依蓝云将努力在第一时间与相关单位配合，争取及时进行处理，但是由此给您造成的损失依蓝云在法律允许的范围内免责。</p>
                    <p>15.2在法律允许的范围内，依蓝云对以下情形导致的服务中断或受阻不承担责任：</p>
                    <p>（1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏。</p>
                    <p>（2）用户或依蓝云的电脑软件、系统、硬件和通信线路出现故障。</p>
                    <p>（3）用户操作不当或用户通过非依蓝云授权的方式使用本服务。</p>
                    <p>（4）程序版本过时、设备的老化和/或其兼容性问题。</p>
                    <p>（5）其他依蓝云无法控制或合理预见的情形。</p>
                    <p>15.3您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，依蓝云不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：</p>
                    <p>（1）来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息。</p>
                    <p>（2）遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失。</p>
                    <p>（3）其他因网络信息或用户行为引起的风险。</p>
                    <p>15.4依蓝云依据本协议约定获得处理违法违规内容的权利，该权利不构成依蓝云的义务或承诺，依蓝云不能保证及时发现违法行为或进行相应处理。</p>
                    <p>15.5在任何情况下，您不应轻信借款、索要密码或其他涉及财产的信息。涉及财产操作的，请一定先核实对方身份，并请经常留意依蓝云有关防范诈骗犯罪的提示。</p>

                    <p>十六、【协议的生效与变更】</p>
                    <p>16.1您使用本服务即视为您已阅读本协议并接受本协议的约束。</p>
                    <p>16.2依蓝云有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的协议条款。</p>
                    <p>16.3本协议条款变更后，如果您继续使用依蓝云提供的软件或服务，即视为您已接受变更后的协议。</p>

                    <p>十七、【服务的变更、中断、终止】</p>
                    <p>17.1您理解并同意，依蓝云基于经营策略的调整，可能会对服务内容进行变更，也可能会中断、中止或终止服务。</p>
                    <p>17.2在依蓝云发生合并、分立、收购、资产转让时，依蓝云可向第三方转让本服务下相关资产；依蓝云也可在单方通知您后，将本协议下部分或全部服务及相应的权利义务转交由第三方运营或履行。具体受让主体以依蓝云通知的为准。</p>
                    <p>17.3如发生下列任何一种情形，依蓝云有权不经通知而中断或终止向您提供服务：</p>
                    <p>（1）根据法律法规规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明。</p>
                    <p>（2）您违反相关法律法规的规定或违反本协议的约定。</p>
                    <p>（3）按照法律法规规定，司法机关或主管部门的要求。</p>
                    <p>（4）出于安全的原因或其他必要的情形。</p>
                    <p>17.4依蓝云有权按本协议8.2条的约定进行收费。若您未按时足额付费，依蓝云有权中断、中止或终止提供服务。</p>
                    <p>17.5您有责任自行备份存储在本服务中的数据。如果您的服务被终止，依蓝云有权从服务器上永久地删除您的数据,法律法规另有规定的除外。服务中止或终止后，依蓝云没有义务向您提供或返还数据。</p>

                    <p>十八、【管辖与法律适用】</p>
                    <p>18.1本协议的成立、生效、履行、解释及纠纷解决等相关事宜，均适用中华人民共和国大陆地区法律（不包括冲突法）。</p>
                    <p>18.2本协议签订地为中华人民共和国广东省深圳市南山区。</p>
                    <p>18.3若您和依蓝云之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地（即中国广东省深圳市南山区）有管辖权的人民法院管辖。</p>
                    <p>18.4本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</p>
                    <p>18.5本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</p>
                    <p>18.6若本协议有中文、英文等多个语言版本，相应内容不一致的，均以中文版的内容为准。</p>

                    <p>十九、【未成年人使用条款】</p>
                    <p>19.1若用户未满18周岁，则为未成年人，应在监护人监护、指导下阅读本协议和使用本服务。</p>
                    <p>19.2未成年人用户涉世未深，容易被网络虚像迷惑，且好奇心强，遇事缺乏随机应变的处理能力，很容易被别有用心的人利用而又缺乏自我保护能力。因此，未成年人用户在使用本服务时应注意以下事项，提高安全意识，加强自我保护：</p>
                    <p>（1）认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活。</p>
                    <p>（2）填写个人资料时，加强个人保护意识，以免不良分子对个人生活造成骚扰。</p>
                    <p>（3）在监护人或老师的指导下，学习正确使用网络。</p>
                    <p>（4）避免陌生网友随意会面或参与联谊活动，以免不法分子有机可乘，危及自身安全。</p>
                    <p>19.3监护人、学校均应对未成年人使用本服务多做引导。特别是家长应关心子女的成长，注意与子女的沟通，指导子女上网应该注意的安全问题，防患于未然。</p>
                    <p>19.4已满18周岁的成年人因任何原因不具备完全民事行为能力的，参照适用本协议之未成年人使用条款之相关约定。</p>

                    二十、【其他】
                    <p>20.1如果您对本协议或本服务有意见或建议，可与依蓝云客户服务部门联系，我们会给予您必要的帮助。</p>
                    `
                },
                '7':{
                    name:'商务咨询',
                    title:'商务咨询',
                    html:`
                    <p style="font-weight: 700;">及时在线直播关注➕ 小蚂蚁VFM</p>
                    <img style="width: 120px;" src="${image4}" alt="">
                    <p>在线及时客服“小蚂蚁”</p>
                    <div style="height: 16px;  width: 100%;"></div>
                    <p style="font-weight: 700;">95128开通落地解决方案咨询</p>
                    <p>WX：KYD95128</p>
                    <img style="width: 120px;" src="${image5}" alt="">
                    <p>咨询热线：4006663721</p>
                    <p>（早9:00-晚18:00）</p>
                    <div style="height: 16px;  width: 100%;"></div>
                    <p style="font-weight: 700;">数字交通TOCC定制落地方案</p>
                    <p>WX/QQ：1990532</p>
                    <img style="width: 120px;" src="${image6}" alt="">
                    <p>咨询热线：4006663721</p>
                    <p>（早9:00-晚18:00）</p>
                    <div style="height: 16px;  width: 100%;"></div>
                    <p style="font-weight: 700;">云计算、TOCC等售后服务</p>
                    <p>QQ：7321283/10013721</p>
                    <p>WX：YLYUN-BZ</p>
                    <img style="width: 120px;" src="${image7}" alt="">
                    <p>咨询热线：4006663721</p>

                    <div style="height: 16px;  width: 100%;"></div>
                    <p>商务合作邮箱：b@uyl.cn</p>

                    <p>投诉建议邮箱：han@uyl.cn</p>

                    <p>产品反馈邮箱：shan@uyl.cn</p>
                    `
                },
                '8':{
                    name:'隐私政策',
                    title:'依蓝云隐私政策',
                    html:`
                    <p>更新时间：2021年4月</p>
                    <p>感谢您访问uyl.cn！依蓝云重视您的私隐， 我们感谢您对依蓝云的信任</p>
                    <p>本隐私政策（下称“隐私政策”）将解释对于您在uyl.cn网站及其子域名（下称“网站”）上的个人信息之处理时间、方式及原因，并规定了您与该信息所持有的相关选择和权利。请仔细阅读——了解我们如何收集和使用您的信息，以及您可以如何控制这些信息非常重要 。</p>
                    <p>请注意，本隐私政策仅适用于我们的网站。</p>
                    <p>关于任何依蓝云产品或服务处理其客户之个人信息的方式，请参阅这些产品或服务的特定隐私政策。</p>
                    <p>关于依蓝云处理其供应商之个人信息的方式，请点击此处。</p>
                    <p>如果您不同意按照本隐私政策所述方式处理您的个人信息，请不要在收到请求时提供您的信息，并停止使用本网站。使用本网站即表示您确认我们在本隐私政策中所述的有关您个人信息的规定。</p>
                    <p>本网站的开发者为青岛依蓝云科技有限公司（下称“我们”或“我们的”）。</p>

                    <p>1. 我们使用的个人信息类型</p>
                    <p>本节说明我们将向您收集不同类型的个人信息，以及我们如何收集这些信息。如果您想进一步了解特定数据类型以及我们如何使用这些数据，请参阅下面的部分“我们如何使用您的个人信息”。</p>
                    <p>以下内容总结概括了我们使用的个人信息类型：</p>
                    <p>您提供给我们的信息</p>
                    <p>在您通过我们网站上的投资者及媒体联系页面提交问询，或使用本隐私政策中指定的联系信息联系我们时，您即向我们提供查询相关的特定信息。</p>
                    <p>Cookie</p>
                    <p>我们使用 Cookie（下称“Cookie”）来提升您的网站使用体验。Cookie 是一种文本文件，当放置在您的设备上时，能够让我们提供特定的特性和功能。</p>
                    <p>儿童</p>
                    <p>儿童不得出于任何目的使用本网站，除非父母或监护人已提供同意。</p>
                    <p>我们所指的儿童为 18 周岁以下的用户。</p>
                    <p>我们不会出于任何目的故意收集儿童的个人信息。如果您认为我们在未经父母/监护人同意的情况下拥有儿童的个人信息，或者您是用户的父母或监护人并希望撤回同意，请通过 b@uyl.cn 与我们联系，我们将会删除此类信息。</p>

                    <p>2. 我们如何使用您的个人信息</p>
                    <p>本节详细说明我们向您收集的个人信息类型以及收集的原因。</p>
                    <p>个人信息-您提供给我们的信息。</p>
                    <p>姓名</p>
                    <p>公司名称</p>
                    <p>职位名称</p>
                    <p>电子邮件地址</p>
                    <p>问询类别</p>
                    <p>消息</p>
                    <p>用途-我们使用这些信息来了解提交问询的人士身份以及与闻讯相关的内容，并联系提交问询的人士。</p>

                    <p>3.  我们如何存储和共享您的个人信息</p>
                    <p>我们公司集团的业务遍及全球，您的个人信息将由我们位于中华人民共和国 (PRC) 的服务器处理。</p>
                    <p>无论我们的服务器位于何处，我们都会根据本隐私政策采取适当措施，保护您的权利。</p>
                    <p>3.1我们仅在合理必要的情况下与第三方共享您的个人信息。这类情况包括：</p>
                    <p>3.1.1提供服务以支持本网站的第三方（包括云服务提供商），这些提供商为支持与您的问询相关的通讯而处理本政策中所明确的信息。除向我们提供服务外，所有向我们提供服务的公司均不得出于任何目的保留、使用或披露您的个人信息。</p>
                    <p>3.1.2我们公司集团内的公司为回应您的问询而处理您的个人信息。所有相关的集团公司只能根据本隐私政策使用您的个人信息。</p>
                    <p>3.1.3监管机构、司法机关和执法机构，以及出于安全、保障或遵守法律之目的的其他第三方。在一些情况下，我们依法须向当局披露您的信息，例如遵守法律义务或程序、强制实施我们的条款、处理与安全或欺诈相关的事宜，或者保护我们的用户。为遵守有效的法律程序条款，如传票、法院命令或搜查令，我们可能在获得或未获得您同意的情况下做出这些披露。一般而言，法律程序的条款禁止我们通知您任何此类披露。如果政府实体未提供所需的传票、法院命令或搜查令，则我们可能会征求您的同意，以按照政府实体的要求披露信息。我们还可能出于以下目的披露您的信息：</p>
                    <p>3.1.3.1强制实施我们的条款和条件及其他协议，包括调查任何可能违反这些文件的行为；</p>
                    <p>3.1.3.2发现、防止或以其他方式解决安全、欺诈或技术问题；或</p>
                    <p>3.1.3.3在法律要求或允许的情况下，保护我们、我们的用户、第三方或公众的权利、财产或安全（为了防止欺诈和降低信用风险而与其他公司和机构交换信息）。</p>
                    <p>3.1.4全部或部分收购我们或我们的业务的第三方。如果出现任意下述情况，我们也可能会向第三方披露您的个人信息：(a) 我们出售、转让、合并、整合或重组业务中的任何部分，或与任何其他企业合并、收购任何其他企业或与其组建合资企业，则我们可能会将您的数据披露给任何潜在买家、新所有者或其他参与我们业务变更的第三方；或 (b) 我们出售或转让我们的任何资产，则我们持有的与您有关的信息可能会作为该等资产的一部分出售，并可能会转让给任何潜在买家、新所有者或其他参与该等出售或转让的第三方。</p>

                    <p>4. 个人信息的安全性</p>
                    <p>无论您的个人信息存储在何处，我们均承诺维护其隐私和完整性。我们的信息安全与访问政策限制对我们的系统和技术进行访问，我们通过使用加密等技术保护措施来保护数据。</p>
                    <p>遗憾的是，通过互联网传输信息并不完全安全。尽管我们将实施并保持合理措施来保护您的个人信息，但我们不能保证通过本网站或以其他方式通过互联网传输的信息的安全性；对于任何信息传输，风险均由您自行承担。</p>

                    <p>5.  数据保留</p>
                    <p>除非法律有所要求，否则我们保留您的数据的时间不会超过保留所需的期限。我们将按照以下方式保留您的信息：</p>
                    <p>您提交查询表格或使用本隐私政策中指定的详细信息联系我们时向我们提供的信息：直至您的查询得到解决，然后在解决后的一百八十 (180) 天内删除该信息，除非您同意保留这些数据并在将来接收更多信息。</p>
                    <p>如果需要我们在上述规定之保留期限之后保留您的信息，例如为了遵守适用法律，我们会将其与其他类型的个人信息分开存储。</p>

                    <p>6. 您的权利</p>
                    <p>您拥有我们持有的有关您的个人信息的相关法定权利（在适用法律和法规允许范围内）。您可以请求访问或更正我们所处理的有关您的数据。我们可能会因遵守数据访问请求而向您收取一定的合理费用。</p>

                    <p>如要行使您的任何权利，请通过 b@uyl.cn 与我们联系。</p>
                    <p>请注意，本网站不涉及任何个性化广告。任何电子邮件仅为回应您提交的查询而发送，并且仅为服务消息。</p>

                    <p>7.  联系和投诉</p>
                    <p>欢迎就本隐私政策提出问题、意见和请求。出于遵守适用隐私法的目的，请向指定的数据保护官提出此类反馈，其联系方式为 b@uyl.cn 。</p>
                    <p>如果您希望就我们处理个人信息的方式提起投诉，请首先通过 b@uyl.cn 与我们联系，我们将努力尽快处理您的请求。</p>

                    <p>8. 变更</p>
                    <p>如果本隐私政策有任何变更，我们将在此发布更新的隐私政策。请定期访问本页面，查看本隐私政策是否有任何更新或变更。</p>

                    `
                },
                '9':{
                    name:'法律声明',
                    title:'法律声明',
                    html:`
                    <p>版权声明：</p>
                    <p>依蓝云公司对其发行的或与合作伙伴共同发行的作品享有版权，受各中国版权公约的保护。</p>
                    <p>对于上述版权内容，超越合理使用范畴、并未经本公司书面许可的使用行为，我公司均保留追究法律责任的权利。</p>
                    <p>商标声明：</p>
                    <p>依蓝云公司对“依蓝云、YL、海鸥形象、咱县打车、可蚁点”等文字或形象均进行了商标注册保护，依蓝云公司的注册商标信息，可见公开的商标注册信息中。</p>
                    <p>未经依蓝云公司的许可而使用上述商标，可能会侵犯依蓝云公司的注册商标权，对于涉嫌侵犯依蓝云公司注册商标权的行为，我公司将保留追究法律责任的权利。</p>
                    <p>相关法律：</p>
                    <p>《中华人民共和国刑法》(节录)</p>
                    <p>《中华人民共和国商标法》</p>
                    <p>《全国人民代表大会常务委员会关于维护互联网安全的决定》</p>
                    <p>《计算机信息网络国际联网安全保护管理办法》</p>
                    <p>《计算机软件保护条例》</p>
                    <p>网站标识：</p>
                    <p>www.uyl.cn、cloud.uyl.cn、www.zdache.cn、livingtrip.uyl.cn等皆为依蓝云公司网站域名。本公司郑重提醒广大依蓝云用户，切勿登陆访问仿冒依蓝云的不法网站，以免上当受害。</p>

                    `
                },
                '10':{
                    name:'明德准则',
                    title:'明德准则',
                    html:`
                    <p>依蓝云信奉正直、进取、协作、创造的价值观，其中，为宣扬正直，我们制定了相关制度，采取了一系列措施，以预防、发现及阻止贪腐或任何违反公司正直价值观的行为。依蓝云的全体员工均需严格遵守明德准则，明的准则明确的禁止各类型的贪腐、舞弊以及任何不符合法律法规的行为。</p>
                    <p>我们鼓励所有员工及业务合作伙伴通过以下方式举报涉及依蓝云员工的任何已经发生或可能发生的贪腐、舞弊及违规行为：</p>
                    <p>举报邮箱: vicky@uyl.cn</p>
                    <p>微信公众号：依蓝云</p>
                    <img style="width: 120px;" src="${image2}" alt="">
                    <p>举报电话: 15908949888</p>
                    <p>信件：山东省青岛市高新区火炬路97号1层102室</p>
                    <p>我们不希望任何人杜撰、捏造事实，或恶意攻击、诽谤依蓝云员工，如发现上述行为，依蓝云公司将保留追究其责任的权利。我们严厉禁止以任何形式报复举报人、证人及调查人员，如存在，依蓝云公司将严厉查处。</p>
                    <p>温馨提示：如果您想对依蓝云相关业务进行咨询及投诉，建议您通过依蓝云公司客服渠道进行反映，您的问题或咨询将得到更快的帮助。</p>
                    `
                },
            }
        }
    },
    watch:{
        "$route":"getPath" 
    },
    mounted () {
        if(this.$route.query.herfIndex){
            this.type=this.$route.query.herfIndex
            document.getElementsByTagName("title")[0].innerText ='依蓝云公司 -' + this.obj[this.type].name;
        }
    },
    methods: {
        getPath(){
            if(this.$route.query.herfIndex){
                this.type=this.$route.query.herfIndex
                document.getElementsByTagName("title")[0].innerText ='依蓝云公司 -' + this.obj[this.type].name;
            }
        }
    }
}
</script>
<style lang="less" scoped>
    .service{
        padding-top: 70px;
        padding-bottom: 80px;
        .tap{
            background-color: #f8f8f8;
            /deep/.el-breadcrumb__item{
                padding: 23px 0;
                font-size: 16px;
            }
            /deep/.el-breadcrumb__separator[class*=icon]{
                margin:0 31px;
            }
            /deep/.el-breadcrumb__inner{
                position: relative;
                color: #009cff;
            }
            /deep/.el-breadcrumb__inner::after{
                content: '';
                position: absolute;
                left: 50%;
                bottom: -23px;
                transform: translateX(-50%);
                width: 32px;
                height: 3px;
                background-color: #009cff;
            }
            /deep/.el-breadcrumb__inner.is-link{
                color: #333;
                font-weight: normal;
            }
            /deep/.el-breadcrumb__inner.is-link::after{
                display: none;
            }
        }

        .content{
            .title{
                margin-top: 70px;
                font-size:36px ;
                margin-bottom: 30px;
                padding-bottom: 40px;
                font-size:36px;
                border-bottom: 1px solid #e6e6e6;
            }
            p{
                font-size: 16px;
                margin-bottom: 30px;
            }
        }
    }
    // 手机端样式
    #app.H5Class {
        .service{
            padding-top: 1.1rem;
            min-height: calc(~ "100%" - 7.8rem);
            padding-bottom: 0.6rem;
            .tap{
                margin-top: 0.01rem;
                border-top: 0.01rem solid #e6e6e6;
                border-bottom: 0.01rem solid #e6e6e6;
                background-color:transparent;
                box-sizing: border-box;
                padding-left: 0.4rem;
                // /deep/.el-breadcrumb__item:nth-child(1){
                //     display: none;
                // }
                /deep/.el-breadcrumb__item{
                    padding:0.4rem 0;
                    font-size: 0.3rem;
                }
                /deep/.el-breadcrumb__item:nth-child(2) .el-breadcrumb__separator[class*=icon]{
                    color: #009cff;
                }
                /deep/.el-breadcrumb__inner::after{
                    bottom: -0.4rem;
                    width: 0.6rem;
                }
                // /deep/.el-breadcrumb__separator[class*=icon]{
                //     display: none;
                // }
            }
            .content{
                .html{
                    padding: 0 0.4rem;
                    font-size: 0.24rem;
                    /deep/ p{
                        font-family:"Microsoft YaHei","微软雅黑","Microsoft JhengHei","华文细黑","STHeiti","MingLiu";
                        }
                }
                .title{
                    padding: 0 0.4rem;
                    margin-top: 0.8rem;
                    margin-bottom: 0.6rem;
                    border-bottom: 1px solid transparent;
                    font-size: 0.34rem;
                }
                p{
                    padding: 0 0.4rem;
                    font-size: 0.2rem;
                }
            }
        }
    }
</style>